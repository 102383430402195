.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app {
  transition: background-color 0.3s, color 0.3s;
  font-family: sans-serif;
  height: 100vh;
}

.light-mode {
  /*color: #005f5a;*/
  color: #004743;
  background-color: white;
  font-family: sans-serif;
}

.dark-mode {
  background-color: #007973;
  color: white;
  font-family: sans-serif;
}

.svg {
  height: 3vmin;
  pointer-events: none;
}
